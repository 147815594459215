import React, { useEffect, useMemo } from 'react';
import ResearcherBox from '../components/ResearcherBox';
import { ResearcherList } from '@typedef/types';
import { useState, useCallback } from 'react';
import images from 'src/assets/images';
import useResearcherStore from '@store/zustand/researcherZustand';
type Props = {
	researcherList: ResearcherList;
	index: number;
};

const ResearcherBoxContainer = ({ researcherList, index }: Props) => {
	const [boxType, setBoxType] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const lastIndex = useMemo(() => currentPage * 3, [currentPage]);
	const firstIndex = useMemo(() => lastIndex - 3, [currentPage]);
	const [topPosition, setTopPosition] = useState('');
	const [leftPosition, setLeftPosition] = useState('');
	const [dotList, setDotList] = useState<string[]>([]);
	const { selectedBoxList, setSelectedBoxList, activeList, setActiveList } =
		useResearcherStore();
	researcherList = researcherList.filter(
		(researcher) => researcher.isStored !== true,
	);
	const requestedItems = useMemo(
		() => researcherList.slice(firstIndex, lastIndex),
		[currentPage, researcherList],
	);
	const totalPosts = useMemo(
		() => researcherList.length,
		[currentPage, researcherList],
	);
	const totalPage = Math.ceil(totalPosts / 3);
	const [goTransition, setGoTransition] = useState(true);
	const handleResize = useCallback(() => {
		setTopPosition(index < 16 ? '-30%' : '-70%');
		setLeftPosition(
			index % 8 < 4 ? '110%' : window.innerWidth < 768 ? '-280%' : '-165%',
		);
	}, [window.innerWidth, topPosition, leftPosition]);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		if (index === 31) {
			setBoxType(3);
		} else if (index > 23) {
			setBoxType(2);
		} else if (index % 8 === 7) {
			setBoxType(1);
		}

		const newDotList = [];
		for (let i = 1; i <= totalPage; i++) {
			if (i === currentPage) {
				newDotList.push(images.dot_w);
			} else {
				newDotList.push(images.dot_g);
			}
		}
		setDotList(newDotList);
	}, [index, currentPage, totalPage, boxType, window.innerWidth]);

	const onBackClick = useCallback(
		(e: React.MouseEvent<HTMLImageElement>) => {
			e.preventDefault();
			e.stopPropagation();
			if (currentPage != 1) {
				setCurrentPage((prev) => prev - 1);
			}
			setGoTransition(false);
		},
		[currentPage, researcherList],
	);

	const onGoClick = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		if (currentPage !== totalPage) {
			setCurrentPage((prev) => prev + 1);
		}
		setGoTransition(true);
	};
	const boxClickHandler = useCallback(
		(e: React.MouseEvent<HTMLElement>) => {
			e.preventDefault();
			e.stopPropagation();
			if (!selectedBoxList[index]) {
				for (let i = 0; i < 32; i++) {
					if (selectedBoxList[i]) {
						setSelectedBoxList(i, false);
						setActiveList(i, false);

						break;
					}
				}
				setSelectedBoxList(index, true);
				setActiveList(index, true);
			} else {
				setSelectedBoxList(index, false);
				setActiveList(index, false);
			}
		},
		[index, selectedBoxList, setSelectedBoxList, activeList, setActiveList],
	);

	return (
		<ResearcherBox
			requestedItems={requestedItems}
			boxType={boxType}
			onBackClick={onBackClick}
			onGoClick={onGoClick}
			boxClickHandler={boxClickHandler}
			topPosition={topPosition}
			leftPosition={leftPosition}
			dotList={dotList}
			goTransition={goTransition}
			selectedBoxList={selectedBoxList}
			index={index}
			activeList={activeList}
			currentPage={currentPage}
			totalPage={totalPage}
		/>
	);
};

export default ResearcherBoxContainer;
