import HomeContainer from "src/pages/Home/containers/HomeContainer";
import ProjectsPage from "src/pages/Projects/ProjectsPage";
import ProjectDetails from "src/pages/Projects/ProjectDetails";
import EventsPage from "src/pages/Events/EventsPage";
import EventDetails from "src/pages/Events/EventDetails";
import Error404 from "src/pages/Error404/Error404";

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  useLocation,
  Route,
  Routes,
  useRoutes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./styles/rootnavigation.style.css";
import FooterContainer from "src/pages/Footer/containers/FooterContainer";
import GnbContainer from "src/pages/Gnb/containers/GnbContainer";
import OrcaContainer from "src/pages/Orca/containers/OrcaContainer";
import ResearcherContainer from "src/pages/Researcher/containers/ResearcherContainer";
import InsightsContainer from "src/pages/Insights/containers/InsightsContainer";
import ResearcherDetailContainer from "src/pages/ResearcherDetail/containers/ResearcherDetailContainer";
import InsightsDetailContainer from "src/pages/InsightsDetail/containers/InsightsDetailContainer";
import useSubscribeStore from "@store/zustand/subscribeZustand";
import { EChange } from "@typedef/types";
import { useTranslation } from "react-i18next";
// /* eslint no-restricted-globals: ["off"] */
type Props = {};
const RootNavigation = ({}: Props) => {
  const location = useLocation();
  const { newsPop, newsEmail, onConfirmNews } = useSubscribeStore();
  const { t } = useTranslation();
  
  function RedirectOrca() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (location.pathname.includes("/orca")) {
        navigate("/orca");
      } else if (
        location.pathname.includes("/researchers") &&
        !location.pathname.includes("/researchers/")
      ) {
        navigate("/researchers");
      } else if (
        location.pathname.includes("/projects") &&
        !location.pathname.includes("/projects/")
      ) {
        navigate("/projects");
      } else if (
        location.pathname.includes("/events") &&
        !location.pathname.includes("/events/")
      ) {
        navigate("/events");
      } else if (
        location.pathname.includes("/insights") &&
        !location.pathname.includes("/insights/")
      ) {
        navigate("/insights");
      } else if (location.pathname.includes("/error404")) {
        navigate("/error404");
      } else if (location.pathname.includes("/")) {
        navigate("/");
      }
    }, [location, navigate]);

    return null;
  }

  return (
    <div className="entire">
      <GnbContainer location={location.pathname} />
      <Routes location={location}>
        <Route
          path="/"
          element={<HomeContainer location={location.pathname} />}
        />

        <Route path="/orca" element={<OrcaContainer />} />
        <Route
          path="/researchers"
          element={<ResearcherContainer location={location.pathname} />}
        />
        <Route
          path="/researchers/:id"
          element={<ResearcherDetailContainer />}
        />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/projects/:id" element={<ProjectDetails />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/events/:id" element={<EventDetails />} />
        <Route path="/insights" element={<InsightsContainer />} />
        <Route path="/error404" element={<Error404 />} />
        <Route path="/insights/:id" element={<InsightsDetailContainer />} />
        <Route path="*" element={<RedirectOrca />} />
      </Routes>

      {newsPop ? (
        <div className="newspop">
          <div className="newspop-box">
            <div className="text">
              {t("subscribe_newsletter_sentence")}
              <br />
              {`${newsEmail}!`}
            </div>
            <button onClick={onConfirmNews}>{t("subscribe")}</button>
          </div>
        </div>
      ) : (
        ""
      )}
      <FooterContainer location={location.pathname} />
    </div>
  );
};

export default RootNavigation;
