import React, { useEffect, useState } from 'react';
import ResearcherMap from '../components/ResearcherMap';
import { ResearcherList, Researchers } from '@typedef/types';
import { getResearchers } from 'src/api/ResearcherAPI';

import images from 'src/assets/images';
import useResearcherStore from '@store/zustand/researcherZustand';
type Props = { route: string };
const ResearcherMapContainer = ({ route }: Props) => {
	const { globalLists, setGlobalLists, black, setBlack } = useResearcherStore();
	useEffect(() => {
		getResearchers().then((data) => {
			const updatedList: ResearcherList[] = Array.from(
				{ length: 32 },
				() => [],
			) as ResearcherList[];
			data.data.map((d: any) => {
				const tempData: Researchers = {
					id: d.id,
					name: d.name,
					profile: d.image,
					location: d.locationNumber,
					department: d.affiliation,
					project: d.projectType,
					isStored: d.isStored,
				};
				if (d.locationNumber - 1 >= 0 && d.locationNumber - 1 < 32) {
					updatedList[d.locationNumber - 1].push(tempData);
				}
			});

			setGlobalLists(updatedList);
			//console.log(globalLists);
		});
		return () => {};
	}, [route]);

	useEffect(() => {
		if (route === 'researchers') {
			setBlack('-black');
		} else {
			setBlack('');
		}
	}, [black, route]);

	return <ResearcherMap globalLists={globalLists} black={black} />;
};

export default ResearcherMapContainer;
