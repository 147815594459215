import { create } from 'zustand';
import { ResearcherList } from '@typedef/types';

const useResearcherStore = create<{
	globalLists: ResearcherList[];
	setGlobalLists: (updatedList: ResearcherList[]) => void;
	selectedBoxList: boolean[];
	setSelectedBoxList: (index: number, boolean: boolean) => void;
	activeList: boolean[];
	setActiveList: (index: number, boolean: boolean) => void;
	black: string;
	setBlack: (changed: string) => void;
}>((set, get) => ({
	globalLists: Array.from({ length: 32 }, () => []) as ResearcherList[],
	setGlobalLists: (updatedList: ResearcherList[]) => {
		set((state) => ({ ...state, globalLists: updatedList }));
	},
	selectedBoxList: Array(32).fill(false),

	setSelectedBoxList: (index, boolean) => {
		set((state) => {
			const updatedList = [...state.selectedBoxList];
			updatedList[index] = boolean;
			return { ...state, selectedBoxList: updatedList };
		});
	},
	activeList: Array(32).fill(false),
	setActiveList: (index, boolean) => {
		set((state) => {
			const updatedList = [...state.activeList];
			updatedList[index] = boolean;
			return { ...state, activeList: updatedList };
		});
	},
	black: '',
	setBlack: (changed: string) => set((state) => ({ ...state, black: changed })),
}));

export default useResearcherStore;
