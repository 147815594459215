const images = {
	logo_w: '/assets/images/logo-w.svg',
	logo_b: '/assets/images/logo-b.svg',
	newlogo_w: '/assets/images/newlogo-w.svg',
	newlogo_b: '/assets/images/newlogo-b.svg',
	logo_orca: '/assets/images/logo-orca.png',
	profile0: '/assets/images/profile0.png',
	profile1: '/assets/images/profile1.jpeg',
	profile2: '/assets/images/profile2.jpeg',
	profile3: '/assets/images/profile3.jpeg',
	profile4: '/assets/images/profile4.jpeg',
	profile5: '/assets/images/profile5.jpeg',
	back_b: '/assets/images/back-b.png',
	back_w: '/assets/images/back-w.png',
	go_b: '/assets/images/go-b.png',
	go_w: '/assets/images/go-w.png',
	view: '/assets/images/view.svg',
	search: '/assets/images/search.svg',
	search_b: '/assets/images/search-b.svg',
	backwithletter: '/assets/images/backwithletter.svg',
	baropen_b: '/assets/images/baropen-b.svg',
	baropen_w: '/assets/images/baropen-w.svg',
	barclose_b: '/assets/images/barclose-b.svg',
	vector36: '/assets/images/vector36.svg',
	beamworks: '/assets/images/beamworks.png',
	logo_s: '/assets/images/logo-s.png',
	back_arrow: '/assets/images/back-arrow.svg',
	paging_bar: '/assets/images/paging_bar.svg',
	checkon: '/assets/images/checkon.svg',
	paging_dot_dark: '/assets/images/paging_dot_dark.svg',
	paging_dot_medium: '/assets/images/paging_dot_medium.svg',
	dot_w: '/assets/images/dot-w.svg',
	dot_g: '/assets/images/dot-g.svg',
	menu: '/assets/images/menu.svg',
	language: '/assets/images/language.svg',
	gnb_back: '/assets/images/gnb-back.svg',
	menu_b: '/assets/images/menu-b.svg',
	language_b: '/assets/images/language-b.svg',
	gnb_back_b: '/assets/images/gnb-back-b.svg',
	logo_gif: '/assets/images/logo-gif.gif',
	logo_gif2: '/assets/images/logo-gif2.gif',
	logo_mp4: '/assets/images/logo-mp4.mp4',
	picto1: '/assets/images/picto1.svg',
	picto2: '/assets/images/picto2.svg',
	picto3: '/assets/images/picto3.svg',
	linkedin: '/assets/images/linkedin.svg',
	twitter: '/assets/images/twitter.svg',
	error404: '/assets/images/error404.png',
	logo_error: '/assets/images/logo_error.jpeg',
};

export default images;
