import React from 'react';
import ResearcherBoxContainer from '../containers/ResearcherBoxContainer';
import { ResearcherList } from '@typedef/types';
import '../styles/researchermap.styles.css';
import Map from '../components/Map';
type Props = {
	globalLists: ResearcherList[];

	black: string;
};

const ResearcherMap = ({
	globalLists,

	black,
}: Props) => {
	return (
		<div className='map-container'>
			<div className={`researcher-map${black}`}>
				<Map />
				<div className='research-box-container'>
					{globalLists.map((researcherList, index) => (
						<ResearcherBoxContainer
							key={index}
							researcherList={researcherList}
							index={index}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ResearcherMap;
