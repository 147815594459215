import { create } from 'zustand';

const useSubscribeStore = create((set, get) => ({
	newsPop: false,
	newsEmail: '',
	onChangeNewsEmail: (e) =>
		set((state) => ({ ...state, newsEmail: e.target.value })),

	onConfirmNews: () =>
		set((state) => ({ ...state, newsPop: false, newsEmail: '' })),
	onNewsSubClick: () => {
		set((state) => ({ ...state, newsPop: true }));
		window.scrollTo(0, 0);
	},

	orcaPop: false,
	orcaEmail: '',
	onChangeOrcaEmail: (e) =>
		set((state) => ({ ...state, orcaEmail: e.target.value })),
	onConfirmOrca: () =>
		set((state) => ({ ...state, orcaPop: false, orcaEmail: '' })),
	onOrcaSubClick: () => {
		set((state) => ({ ...state, orcaPop: true }));
		window.scrollTo(0, 0);
	},
}));

export default useSubscribeStore;
